import { Selector as AntSelector, SelectorProps } from 'antd-mobile'

const Selector = (props: SelectorProps<any>) => {
  return (
    <AntSelector
      {...props}
      style={{
        '--border': '2px solid rgba(55, 194, 187, 0.15)',
        '--border-radius': '10px',
        color: '#B1E7E5',
      }}
    />
  )
}

export default Selector
