import { useTranslation } from 'react-i18next'
import { Title } from '../../components/UI/Typography'
import Input from '../../components/AntD/Input'
import Selector from '../../components/AntD/Selector'
import { CSSProperties, PropsWithChildren, useEffect, useState } from 'react'
import BottomButton from '../../components/AntD/Button'
import { drawerStyle } from './'
import axios from 'axios'

const FormLine = ({ children }: PropsWithChildren) => {
  return (
    <div
      style={{
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </div>
  )
}
const Label = ({ children }: PropsWithChildren) => {
  return (
    <div style={{ textAlign: 'left', marginBottom: '8px', fontSize: '18px' }}>
      {children}
    </div>
  )
}

const inputStyle: CSSProperties = {
  border: '1px solid',
  background: '#fff',
}

const HouseForm = ({ onFinish }: any) => {
  const { t } = useTranslation()
  const [feet, setFeet] = useState('')
  const [cleaningTimes, setCleaningTimes] = useState('weekly')
  const [request, setRequest] = useState('')
  const [district, setDistrict] = useState('')
  const [phone, setPhone] = useState('')
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState({
    feet: false,
    phoneNumber: false,
    district: false,
  })

  const validate = async () => {
    const isFeetValid = feet.length > 0
    const isPhoneNumberValid = phone.length > 6
    const isDistrictValid = district.length > 0
    setHasErrors({
      feet: !isFeetValid,
      phoneNumber: !isPhoneNumberValid,
      district: !district,
    })
    return isFeetValid && isPhoneNumberValid && isDistrictValid
  }

  const [validationFlag, setValidationFlag] = useState(false)

  useEffect(() => {
    if (validationFlag) {
      validate()
    }
  }, [phone, district, feet, validationFlag])

  return (
    <div style={drawerStyle}>
      <div
        style={{
          textAlign: 'center',
          padding: '0 40px',
          flex: 1,
        }}
      >
        <Title
          style={{
            display: 'block',
            color: 'black',
            textAlign: 'center',
            margin: '0 0 32px',
          }}
        >
          {t('house_cleaning.house_form.title')}
        </Title>
        <FormLine>
          <Label> {t('office.form.number_of_feet')}</Label>
          <Input
            hasError={hasErrors.feet}
            style={inputStyle}
            value={feet}
            onChange={value => setFeet(value)}
            placeholder={t('office.form.number_of_feet.placeholder')}
          />
        </FormLine>
        <FormLine>
          <Label> {t('office.form.cleaning_times')}</Label>
          <Selector
            columns={3}
            options={[
              {
                label: t('office.form.cleaning_times_once'),
                value: 'once',
              },
              {
                label: t('office.form.cleaning_times_weekly'),
                value: 'weekly',
              },
              {
                label: t('office.form.cleaning_times_monthly'),
                value: 'monthly',
              },
            ]}
            defaultValue={[cleaningTimes]}
            onChange={selected => {
              setCleaningTimes(selected[0])
            }}
          />
        </FormLine>
        <FormLine>
          <Label> {t('office.form.any_request')}</Label>
          <Input
            style={inputStyle}
            value={request}
            onChange={value => setRequest(value)}
            placeholder={t('office.form.any_request.placeholder')}
          />
        </FormLine>
        <FormLine>
          <Label> {t('office.form.district')}</Label>
          <Input
            hasError={hasErrors.district}
            style={inputStyle}
            value={district}
            onChange={value => setDistrict(value)}
            placeholder={t('office.form.district.placeholder')}
          />
        </FormLine>
        <FormLine>
          <Label> {t('office.form.phone')}</Label>
          <Input
            hasError={hasErrors.phoneNumber}
            style={inputStyle}
            value={phone}
            onChange={value => setPhone(value)}
            placeholder={t('office.form.phone.placeholder')}
          />
        </FormLine>
      </div>
      <BottomButton
        loading={loading}
        disabled={hasErrors.feet || hasErrors.district || hasErrors.phoneNumber}
        onClick={async () => {
          try {
            setLoading(true)
            setValidationFlag(true)
            const isValid = await validate()
            if (!isValid) return
            await axios.post('/api/order/office/submit', {
              feet,
              frequency: cleaningTimes,
              extraRequest: request,
              district,
              phoneNumber: phone,
            })
            onFinish()
          } catch (e) {
            console.error(e)
          } finally {
            setLoading(false)
          }
        }}
        style={{
          width: '80%',
          margin: '32px auto 0',
        }}
      >
        {t('office.page.bottom_button.next')}
      </BottomButton>
    </div>
  )
}

export default HouseForm
