import { PropsWithChildren, useState } from 'react'
import { ActionSheet } from 'antd-mobile'
import './index.css'
import './global.css'
import { Body } from '../UI/Typography'
import { LangKey, useLangKey } from '../../providers/LangkeyProvider'

const actions = [
  { text: 'English', key: 'en' },
  { text: '中文', key: 'zh' },
]

const Layout = ({ children }: PropsWithChildren) => {
  const [visible, setVisible] = useState(false)
  const { setLangKey } = useLangKey()
  return (
    <div className="layout">
      <Body
        style={{
          position: 'fixed',
          color: '#fff',
          top: '20px',
          right: '20px',
        }}
        onClick={() => setVisible(true)}
      >
        English / 繁体
      </Body>
      {children}
      <ActionSheet
        visible={visible}
        actions={actions}
        onClose={() => setVisible(false)}
        onAction={action => {
          setLangKey(action.key as LangKey)
          setVisible(false)
        }}
      />
    </div>
  )
}
export default Layout
