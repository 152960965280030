import { lazy, Suspense } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import CleaningOffice from './pages/CleaningOffice'
import MoveIn from './pages/MoveIn'
import Renovation from './pages/Renovation'

const Home = lazy(() => import('./pages/Home'))
const CleaningHouse = lazy(() => import('./pages/CleaningHouse'))
const Success = lazy(() => import('./pages/Success'))

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/house-cleaning',
    element: <CleaningHouse />,
  },
  {
    path: '/house-cleaning/move-in',
    element: <MoveIn />,
  },
  {
    path: '/house-cleaning/renovation',
    element: <Renovation />,
  },
  {
    path: '/office-cleaning',
    element: <CleaningOffice />,
  },
  {
    path: '/success',
    element: <Success />,
  },
  {
    path: '*',
    element: <Home />,
  },
])
function Roots() {
  return (
    <Suspense fallback={null}>
      <RouterProvider router={router} />
    </Suspense>
  )
}

export default Roots
