import Button from '../../components/AntD/Button'
import { CSSProperties, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import RenovationForm from './RenovationForm'
import styles from './index.module.css'
import RenovationSuccess from './RenovationSuccess'

const stepForm = 'form'
const stepUpload = 'upload'
const stepSuccess = 'success'
type Steps = typeof stepUpload | typeof stepForm | typeof stepSuccess

const wrapperStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  height: '100%',
}

export const drawerStyle: CSSProperties = {
  borderRadius: '20px 20px 0px 0px',
  background: '#fff',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '40px',
  paddingTop: '28px',
  minHeight: '70%',
}

const Renovation = () => {
  const { t } = useTranslation()
  const [step, setStep] = useState<Steps>(stepForm)
  const navigate = useNavigate()
  const getClassName = () => {
    if (step === stepForm) return styles.banner
    if (step === stepSuccess) return styles.successBanner
    return ''
  }
  const handleFormClick = () => {
    setStep(stepSuccess)
  }
  return (
    <div style={wrapperStyle} className={getClassName()}>
      {step === stepForm && <RenovationForm onFinish={handleFormClick} />}
      {step === stepSuccess && (
        <div style={{ ...drawerStyle, minHeight: '74%' }}>
          <RenovationSuccess />
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'flex-end',
            }}
          >
            <Button
              variant="secondary"
              onClick={() => {
                navigate('/')
              }}
              style={{
                width: '80%',
                margin: '32px auto 0',
              }}
            >
              {t('office.success.back')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Renovation
