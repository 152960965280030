import { useTranslation } from 'react-i18next'
import { Title } from '../../components/UI/Typography'
import SuccessIcon from '../../images/check.png'
import { ReactComponent as IconOne } from '../../images/svgs/1.svg'
import { ReactComponent as IconTwo } from '../../images/svgs/2.svg'
import { ReactComponent as IconThree } from '../../images/svgs/3.svg'
import { ReactComponent as IconFour } from '../../images/svgs/4.svg'
import { ReactComponent as IconFive } from '../../images/svgs/5.svg'
import { ReactComponent as IconSix } from '../../images/svgs/6.svg'
import { ReactComponent as IconSeven } from '../../images/svgs/7.svg'
import { ReactComponent as IconEight } from '../../images/svgs/8.svg'
import { ReactComponent as IconNine } from '../../images/svgs/9.svg'
import { ReactComponent as IconTen } from '../../images/svgs/10.svg'
import { CSSProperties } from 'react'

const lineStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}

const iconStyle: CSSProperties = {
  width: '26px',
  height: '26px',
  backgroundColor: 'rgb(55, 194, 187)',
  borderRadius: '36px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const svgStyle: CSSProperties = {
  width: '18px',
  height: '18px',
}

const MoveInSuccess = () => {
  const { t } = useTranslation()
  return (
    <div style={{ padding: '0 40px' }}>
      <Title
        style={{
          display: 'flex',
          alignItems: 'center',
          color: 'black',
          margin: '0 0 32px',
          gap: '12px',
        }}
      >
        <img
          src={SuccessIcon}
          style={{ display: 'inline-block', height: '60px', width: '60px' }}
        />
        <div>
          <p>{t('office.success.title')}</p>
          <p>{t('office.success.sub_title')}</p>
        </div>
      </Title>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <p style={{ color: '#8B8B8B' }}>{t('office.success.info')}</p>
        <div style={lineStyle}>
          <div style={iconStyle}>
            <IconOne style={svgStyle} />
          </div>
          <span>{t('office.success.empty_bins')}</span>
        </div>
        <div style={lineStyle}>
          <div style={iconStyle}>
            <IconTwo style={svgStyle} />
          </div>
          <span>{t('office.success.wipe_down')}</span>
        </div>
        <div style={lineStyle}>
          <div style={iconStyle}>
            <IconThree style={svgStyle} />
          </div>
          <span>{t('office.success.dusting')}</span>
        </div>
        <div style={lineStyle}>
          <div style={iconStyle}>
            <IconFour style={svgStyle} />
          </div>
          <span>{t('office.success.main_door_cleaning')}</span>
        </div>
        <div style={lineStyle}>
          <div style={iconStyle}>
            <IconFive style={svgStyle} />
          </div>
          <span>{t('office.success.floor_cleaning')}</span>
        </div>
        <div style={lineStyle}>
          <div style={iconStyle}>
            <IconSix style={svgStyle} />
          </div>
          <span>{t('office.success.printer_cleaning')}</span>
        </div>
        <div style={lineStyle}>
          <div style={iconStyle}>
            <IconSeven style={svgStyle} />
          </div>
          <span>{t('office.success.pantry_cleaning')}</span>
        </div>
        <div style={lineStyle}>
          <div style={iconStyle}>
            <IconEight style={svgStyle} />
          </div>
          <span>{t('office.success.indoor_cleaning')}</span>
        </div>
        <div style={lineStyle}>
          <div style={iconStyle}>
            <IconNine style={svgStyle} />
          </div>
          <span>{t('office.success.door_cleaning')}</span>
        </div>
        <div style={lineStyle}>
          <div style={iconStyle}>
            <IconTen style={svgStyle} />
          </div>
          <span>{t('office.success.washroom_cleaning')}</span>
        </div>
      </div>
    </div>
  )
}

export default MoveInSuccess
