import Layout from './components/Layout'
import I18nProvider from './providers/I18nProvider'
import LangKeyProvider from './providers/LangkeyProvider'
import { ConfigProvider } from 'antd-mobile'
import enUS from 'antd-mobile/es/locales/en-US'
import Roots from './roots'

function App() {
  return (
    <LangKeyProvider>
      <I18nProvider>
        <ConfigProvider locale={enUS}>
          <Layout>
            <Roots />
          </Layout>
        </ConfigProvider>
      </I18nProvider>
    </LangKeyProvider>
  )
}

export default App
