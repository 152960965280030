import { CSSProperties, PropsWithChildren } from 'react'
import styles from './index.module.css'

export const Body = ({
  children,
  style,
  onClick,
}: PropsWithChildren<{ style?: CSSProperties; onClick?: () => void }>) => {
  return (
    <span className={styles.typographyBody} style={style} onClick={onClick}>
      {children}
    </span>
  )
}

export const SubTitle = ({
  children,
  style,
}: PropsWithChildren<{ style?: CSSProperties }>) => {
  return (
    <span className={styles.typographySubTitle} style={style}>
      {children}
    </span>
  )
}

export const Title = ({
  children,
  style,
}: PropsWithChildren<{ style?: CSSProperties }>) => {
  return (
    <span className={styles.typographyTitle} style={style}>
      {children}
    </span>
  )
}
