import { Input as AntInput, InputProps } from 'antd-mobile'

type Props = InputProps & { hasError?: boolean }

const Input = (props: Props) => {
  const { hasError } = props
  const color = Boolean(hasError)
    ? 'rgb(255, 0, 0, 0.55)'
    : 'rgba(55, 194, 187, 0.15)'
  return (
    <AntInput
      {...props}
      style={{
        border: `2px solid ${color}`,
        borderRadius: '10px',
        height: '41px',
        color: '#B1E7E5',
        paddingLeft: '20px',
      }}
    />
  )
}

export default Input
