import { useSingleton } from '../hooks/useSingleton'
import { createInstance } from 'i18next'
import en from '../locales/en.json'
import zh from '../locales/zh.json'
import { PropsWithChildren, useEffect } from 'react'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import { useLangKey } from './LangkeyProvider'
type LangKey = 'en' | 'zh'
function initI18n(langKey: LangKey) {
  const i18n = createInstance({
    resources: {
      en: {
        translation: en,
      },
      zh: {
        translation: zh,
      },
    },
    lng: langKey,
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  })
  i18n.use(initReactI18next).init()
  return i18n
}

const I18nProvider = ({ children }: PropsWithChildren) => {
  const { langKey } = useLangKey()
  const i18nInstance = useSingleton(() => initI18n(langKey))

  useEffect(() => {
    if (i18nInstance.language !== langKey) {
      i18nInstance.changeLanguage(langKey)
    }
  }, [langKey, i18nInstance])

  return <I18nextProvider i18n={i18nInstance}>{children}</I18nextProvider>
}

export default I18nProvider
