import { PropsWithChildren, createContext, useContext, useState } from 'react'

export type LangKey = 'en' | 'zh'
type LangKeyContextValue = {
  langKey: LangKey
  setLangKey: (lng: LangKey) => void
}
const LangKeyContext = createContext<LangKeyContextValue>(
  {} as LangKeyContextValue
)
export const useLangKey = () => useContext(LangKeyContext)

export const LangKeyProvider = ({ children }: PropsWithChildren<any>) => {
  const [langKey, setLangKey] = useState<LangKey>('en')
  const value = {
    langKey,
    setLangKey: (lng: LangKey) => {
      setLangKey(lng)
    },
  }
  return (
    <LangKeyContext.Provider value={value}>{children}</LangKeyContext.Provider>
  )
}

export default LangKeyProvider
