import { ButtonProps, Button as AntButton } from 'antd-mobile'
import { CSSProperties } from 'react'
type OtherProps = {
  variant?: 'primary' | 'secondary'
}

const Button = ({
  children,
  style,
  variant = 'primary',
  ...rest
}: ButtonProps & OtherProps) => {
  const primaryColor: CSSProperties = {
    backgroundColor: '#37C2BB',
    color: '#FFF',
  }
  const secondaryColor: CSSProperties = {
    backgroundColor: '#FFF',
    color: '#37C2BB',
    borderColor: '#37C2BB',
  }

  const colors = variant === 'primary' ? primaryColor : secondaryColor

  return (
    <AntButton
      {...rest}
      style={{
        borderRadius: '10px',
        textAlign: 'center',
        fontSize: '18px',
        height: '56px',
        ...colors,
        ...style,
      }}
    >
      {children}
    </AntButton>
  )
}
export default Button
