import { useTranslation } from 'react-i18next'
import { Title } from '../../components/UI/Typography'
import Input from '../../components/AntD/Input'
import { CSSProperties, PropsWithChildren, useEffect, useState } from 'react'
import { Button, CalendarPicker } from 'antd-mobile'
import BottomButton from '../../components/AntD/Button'
import dayjs from 'dayjs'
import { drawerStyle } from './'
import axios from 'axios'

const FormLine = ({ children }: PropsWithChildren) => {
  return (
    <div
      style={{
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </div>
  )
}
const Label = ({ children }: PropsWithChildren) => {
  return (
    <div style={{ textAlign: 'left', marginBottom: '8px', fontSize: '18px' }}>
      {children}
    </div>
  )
}

const inputStyle: CSSProperties = {
  border: '1px solid',
  background: '#fff',
}

const RenovationForm = ({ onFinish }: any) => {
  const { t } = useTranslation()
  const [feet, setFeet] = useState('')
  const [date, setDate] = useState(new Date())
  const [phoneNumber, setPhoneNumber] = useState('')
  const [petsNumber, setPetsNumber] = useState('')
  const [datePickerVisible, setDatePickerVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState({
    feet: false,
    phoneNumber: false,
    petsNumber: false,
  })

  const validate = async () => {
    const isFeetValid = feet.length > 0
    const isPhoneNumberValid = phoneNumber.length > 6
    const isPetValid = petsNumber.length > 0
    setHasErrors({
      feet: !isFeetValid,
      phoneNumber: !isPhoneNumberValid,
      petsNumber: !isPetValid,
    })
    return isFeetValid && isPhoneNumberValid && isPetValid
  }

  const [validationFlag, setValidationFlag] = useState(false)

  useEffect(() => {
    if (validationFlag) {
      validate()
    }
  }, [phoneNumber, petsNumber, feet, validationFlag])

  return (
    <div style={drawerStyle}>
      <div
        style={{
          textAlign: 'center',
          padding: '0 40px',
          flex: 1,
        }}
      >
        <Title
          style={{
            display: 'block',
            color: 'black',
            textAlign: 'center',
            margin: '0 0 32px',
          }}
        >
          {t('house_cleaning.house_form.title')}
        </Title>
        <FormLine>
          <Label> {t('move_in.form.number_of_feet')}</Label>
          <Input
            hasError={hasErrors.feet}
            style={inputStyle}
            value={feet}
            onChange={value => {
              setFeet(value)
            }}
            placeholder={t('move_in.form.number_of_feet.placeholder')}
          />
        </FormLine>
        <FormLine>
          <Label> {t('move_in.form.select_date')}</Label>
          <Button
            onClick={() => setDatePickerVisible(true)}
            style={{
              textAlign: 'left',
              paddingLeft: '20px',
              border: '2px solid rgba(55, 194, 187, 0.15)',
              borderRadius: '10px',
              height: '48px',
            }}
          >
            {dayjs(date).format('YYYY-MM-DD')}
          </Button>
          <CalendarPicker
            visible={datePickerVisible}
            selectionMode="single"
            defaultValue={date}
            onChange={date => {
              if (date) {
                setDate(date)
              }
            }}
            onClose={() => setDatePickerVisible(false)}
            onMaskClick={() => setDatePickerVisible(false)}
          />
        </FormLine>
        <FormLine>
          <Label> {t('move_in.form.phone_number')}</Label>
          <Input
            hasError={hasErrors.phoneNumber}
            style={inputStyle}
            value={phoneNumber}
            onChange={value => {
              setPhoneNumber(value)
            }}
            placeholder={t('move_in.form.phone_number.placeholder')}
          />
        </FormLine>
        <FormLine>
          <Label> {t('move_in.form.any_pets')}</Label>
          <Input
            hasError={hasErrors.petsNumber}
            style={inputStyle}
            value={petsNumber}
            onChange={value => {
              setPetsNumber(value)
            }}
            placeholder={t('move_in.form.any_pets.placeholder')}
          />
        </FormLine>
      </div>
      <BottomButton
        loading={loading}
        disabled={
          hasErrors.feet || hasErrors.petsNumber || hasErrors.phoneNumber
        }
        onClick={async () => {
          try {
            setLoading(true)
            setValidationFlag(true)
            const isValid = await validate()
            if (!isValid) return
            await axios.post('/api/order/renovation/submit', {
              feet,
              petsNumber,
              phoneNumber,
              cleaningDate: dayjs(date).format('YYYY-MM-DD'),
            })
            onFinish()
          } catch (e) {
            console.error(e)
          } finally {
            setLoading(false)
          }
        }}
        style={{
          width: '80%',
          margin: '32px auto 0',
        }}
      >
        {t('office.page.bottom_button.next')}
      </BottomButton>
    </div>
  )
}

export default RenovationForm
